import React from "react"
import Consts from "./consts";

interface _Name {
    ID: number,
    verein_ID: number,
    fullName: string,

    logo?: string,

}


type _domainMap = Record<string, _Name>
const memberStatesStandard = {
    0: "Nichtmitglied",
    1: "ordentliches Mitglied",
    2: "außerordentliches Mitglied",
    3: "unterstützendes Mitglied",

    400: "befreites Mitglied",
    401: "ruhendes Mitglied",
    410: "gesperrte Person",
    //402: "Trainer",

    500: "Zeugwart Stellvertretung",
    600: "Zeugwart",

    700: "Sportliche Leitung Stellvertretung",
    800: "Sportliche Leitung",
    900: "Kassier Stellvertretung",
    1000: "Kassier",
    1100: "Schriftführer Stellvertretung",
    1200: "Schriftführer",
    1300: "Obfrau/Obmann Stellvertretung",
    1400: "Obfrau/Obmann",


    //100: "Vorstand"
};

const stripeMethods = ["sepa", "card", "sofort", "bank", "voucher"];

// https://www.iban-rechner.de/iban_validieren.html


const domainMapInitial: _domainMap = {
        "mein-turnverein.at": {
            "ID": 0,
            "fullName": "Allgemeiner Turnverein St. Valentin 1911",
            "logo": "<img style=\"background-color: #bdd94d; padding: 20px; margin-bottom: 10px\" height=\"103\" width=\"auto\" src=\"https://www.turnverein-st-valentin.at/files/cto_layout/themedesigner/uploads/logo_web_weiss.svg\">",
            "verein_ID": 0
        },
        "meine.bewegungswelt.at": {"ID": 3, "fullName": "Bewegungswelt von Theresa Gansterer", "logo": "<img style=\"background-color: #bdd94d\" height=\"100\" src=\"https://bewegungswelt.at/wp-content/uploads/2019/08/cropped-Logo-gr\u00f6\u00dfer-1-300x200.jpg\">", "verein_ID": 3},
        "atv-andorf.vereinfacht.at": {
            "ID": 4,
            "fullName": "\u00d6TB Allgemeiner Turnverein Andorf",
            "logo": "<p><img id=\"comp-jnlkxecbimgimage\" src=\"https://static.wixstatic.com/media/69b39a_c2d9b5e1b11a45b89b266fb2d5621822~mv2.png/v1/fill/w_230,h_166,al_c,usm_0.66_1.00_0.01/ATV%20Andorf%20Logo_bearbeitet.png\" alt=\"ATV Andorf Logo_bearbeitet.png\" data-type=\"image\" /></p>",
            "verein_ID": 4
        },
        "tv-taufkirchen.vereinfacht.at": {"ID": 5, "fullName": "Turnverein Taufkirchen", "logo": "<img style=\"padding: 0px; margin-top: -5px\" width=\"200\" height=\"auto\" src=\"https://mein.vereinfacht.at/static/logos/taufkirchen.png\">", "verein_ID": 5},
        "schwanenstadt.vereinfacht.at": {"ID": 6, "fullName": "Turnverein Schwanenstadt", "logo": "<img style=\"background-color: #bdd94d; padding: 20px; marginTop: -5px\" width=\"300\" height=\"auto\" src=\"https://mein.vereinfacht.at/static/logos/schwanenstadt.jpg\">", "verein_ID": 6},
        "turnvereinmondsee.vereinfacht.at": {"ID": 7, "fullName": "Turn- und Sportverein Mondsee", "logo": "<div><img style=\"padding: 20px; padding-bottom: 0; margin-top: -30px\" width=\"150\" height=\"auto\" src=\"https://mein.vereinfacht.at/static/logos/mondsee.png\"></div>", "verein_ID": 7},
        "itv.vereinfacht.at": {
            "ID": 8,
            "fullName": "Innsbrucker Turnverein 1863 (1849)",
            "logo": "<div><img style=\"padding: 20px; margin-top: -5px\" width=\"150\" height=\"auto\" src=\"https://mein.vereinfacht.at/static/logos/innsbruck.png\" > <h1 style=\"margin-top: -10px; color: var(--mainColor)\">Innsbrucker Turnverein</h1></div>",
            "verein_ID": 8
        },
        "mein.oetb-linz.at": {
            "ID": 9,
            "fullName": "\u00d6TB Turnverein Linz",
            "logo": "<p><a href=\"http://www.oetb-linz.at\"><img src=\"https://vereinfacht-public.s3.amazonaws.com/blockFiles/vereinfacht_tinyupload/1672773751-fc5853a96d8a0742ec5e3711bdab88cdadc80b69.png\" alt=\"\" width=\"150\" height=\"126\" /></a></p>",
            "verein_ID": 9
        },
        "oetb-ausseerland.vereinfacht.at": {"ID": 10, "fullName": "\u00d6TB Ausseerland", "logo": "<div><h1 style=\"marginTop: 50px, color: var(--mainColor)\">\u00d6TB Ausseerland</h1></div>", "verein_ID": 10},
        "tv-steyr.vereinfacht.at": {"ID": 11, "fullName": "Turnverein Steyr 1861", "logo": "<img className=\"img-fluid\" src=\"https://mein.vereinfacht.at/static/logos/steyr.png\" width=\"220\" height=\"auto\" alt=\"\"/>", "verein_ID": 11},
        "atv-schwertberg.vereinfacht.at": {"ID": 12, "fullName": "Allgemeiner Turnverein Schwertberg", "logo": "<img className=\"img-fluid\" src=\"https://mein.vereinfacht.at/static/logos/schwertberg.gif\" width=\"300\" height=\"auto\" alt=\"\"/>", "verein_ID": 12},
        "gunclub.vereinfacht.at": {"ID": 13, "fullName": "Gun Club Lustenau", "logo": "<img className=\"img-fluid\" src=\"https://mein.vereinfacht.at/static/logos/gunclub.png\" width=\"300\" height=\"auto\" alt=\"\"/>", "verein_ID": 13},
        "atv-andorf-schwimmen.vereinfacht.at": {
            "ID": 14,
            "fullName": "Schwimmen / \u00d6TB Allgemeiner Turnverein Andorf",
            "logo": "<p><img src=\"https://drive.google.com/file/d/1g7PFfIFnuUcYWxNlDUXa9K0dxf-mvcmN/view?usp=sharing\" alt=\"\" width=\"237\" height=\"181\" /><img src=\"https://drive.google.com/file/d/1g7PFfIFnuUcYWxNlDUXa9K0dxf-mvcmN/view?usp=sharing\" alt=\"\" /></p>",
            "verein_ID": 14
        },
        "vbc-steyr.vereinfacht.at": {"ID": 15, "fullName": "Union Volleyballclub Steyr", "logo": "<p><img src=\"https://mein.vereinfacht.at/static/logos/vbc_steyr.jpg\" alt=\"Union VBC Steyr Logo_bearbeitet.png\" width=\"115\" height=\"130\" data-type=\"image\" /></p>", "verein_ID": 15},
        "oetb-pichling.vereinfacht.at": {"ID": 16, "fullName": "\u00d6TB Turnverein Pichling", "logo": "<img alt=\"\u00d6TB Pichling\" data-type=\"image\" itemProp=\"image\" src=\"https://mein.vereinfacht.at/static/logos/pichling.jpg\" width=\"250\"/>", "verein_ID": 16},
        "valentinmuseum.vereinfacht.at": {"ID": 17, "fullName": "Geschichtliches Museum der Stadt St. Valentin", "logo": "<img src=\"https://valentinmuseum.at/source/logo.png\" width=\"320\"/>", "verein_ID": 17},
        "atv-weyer.vereinfacht.at": {"ID": 18, "fullName": "Allgemeiner Turnverein Weyer", "logo": "<img alt=\"ATV Weyer\" data-type=\"image\" itemProp=\"image\" src=\"https://mein.vereinfacht.at/static/logos/weyer.png\" width=\"150\"/>", "verein_ID": 18},
        "kunstturnen-ternitz.vereinfacht.at": {
            "ID": 19,
            "fullName": "ATSV Ternitz Sektion Kunstturnen",
            "logo": "<p><code><img id=\"Grafik_x0020_1\" style=\"font-family: Calibri, sans-serif; font-size: 14.666666984558105px; width: 136px; height: 137px; display: block; margin-left: auto; margin-right: auto;\" src=\"//mein.vereinfacht.at/static/logos/ternitz.png\" alt=\"signature_2791153694\" width=\"67\" height=\"67\" border=\"0\" /></code><img width=\"150\" data-type=\"image\" /></p>",
            "verein_ID": 19
        },
        "oetb-schaerding.vereinfacht.at": {"ID": 21, "fullName": "\u00d6TB Turnverein Sch\u00e4rding 1861", "logo": "<h1 style=\"font-size: 160%\">\u00d6TB Turnverein Sch\u00e4rding 1861</h1>", "verein_ID": 21},
        "stv1861.vereinfacht.at": {"ID": 22, "fullName": "STV Salzburger Turnverein 1861", "logo": "<h1 style=\"font-size: 160%\">STV Salzburger Turnverein 1861</h1>", "verein_ID": 22},
        "artanddance-connection.vereinfacht.at": {"ID": 23, "fullName": "Art & Dance Connection", "logo": "<h1 style=\"font-size: 160%\">Art & Dance Connection</h1>", "verein_ID": 23},
        "oetb-brunnenthal.vereinfacht.at": {"ID": 24, "fullName": "\u00d6TB Turnverein Brunnenthal 1977", "logo": "<h1 style=\"font-size: 160%\">\u00d6TB Turnverein Brunnenthal 1977</h1>", "verein_ID": 24},
        "artanddance-center.vereinfacht.at": {"ID": 25, "fullName": "Art & Dance Center", "logo": "<h1 style=\"font-size: 160%\">Art & Dance Center</h1>", "verein_ID": 25},
        "turnsport-tirol.vereinfacht.at": {"ID": 26, "fullName": "Turnsport Tirol", "logo": "<h1 style=\"font-size: 160%\">Turnsport Tirol</h1>", "verein_ID": 26}
    }

;
const domainMap: _domainMap = Object.keys(domainMapInitial).reduce((obj, curr) => ({
    ...obj,
    [curr]: {
        ...domainMapInitial[curr],

    }
}), {});
const domainName = window.location.hostname.split(".").slice(-2).join(".");
const domainNameLong = window.location.hostname.split(".").slice(-3).join(".");
const clubMap = Object.keys(domainMap).reduce((obj, curr) => ({...obj, [domainMap[curr].verein_ID]: curr}), {});


let ClubPart = domainMap[domainNameLong] || (
    (domainName.indexOf("localhost") > -1 || domainName.indexOf("10.0.0") > -1 || !domainMap[domainName]) ?
        domainMap["vereinfacht.at"] :
        domainMap[domainName]
);
// @ts-ignore
if (window?.VEREINfachtConfig?.verein_ID) {
// @ts-ignore
    ClubPart = Object.values(domainMap).find(a => a.verein_ID === window?.VEREINfachtConfig?.verein_ID) || domainMap["vereinfacht.at"];
}

if (Consts.f(true, false)) {

    //ClubPart = domainMap["tv-enns.vereinfacht.at"];
    //ClubPart = domainMap["schwanenstadt.vereinfacht.at"];
    //ClubPart = domainMap["meine.bewegungswelt.at"];
    //ClubPart = domainMap["mein-turnverein.at"];
    //ClubPart = domainMap["vereinfacht.at"];
    //ClubPart = domainMap["my.vereinfacht.com"];
    //ClubPart = domainMap["atv-andorf.vereinfacht.at"];
    ClubPart = domainMap["mein.oetb-linz.at"];
    //ClubPart = domainMap["itv.vereinfacht.at"];
    //ClubPart = domainMap["tv-taufkirchen.vereinfacht.at"];
    //ClubPart = domainMap["tv-steyr.vereinfacht.at"];
    //ClubPart = domainMap["atv-schwertberg.vereinfacht.at"];
    //ClubPart = domainMap["gunclub.vereinfacht.at"];
    //ClubPart = domainMap["atv-andorf-schwimmen.vereinfacht.at"];
    //ClubPart = domainMap["vbc-steyr.vereinfacht.at"];
    //ClubPart = domainMap["turnvereinmondsee.vereinfacht.at"];
    //ClubPart = domainMap["valentinmuseum.vereinfacht.at"];
    //ClubPart = domainMap["atv-weyer.vereinfacht.at"];
    //ClubPart = domainMap["atv-test.vereinfacht.at"];

}

const Club = {
    ...ClubPart,
}; // domain: window.location.host


export {domainMap, clubMap};

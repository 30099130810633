import React, {Component,} from "react"
import {Form} from 'react-formio';
import ReactMapGL, {Marker, NavigationControl} from "react-map-gl"
import {FaHome, FaMapPin} from "react-icons/fa/index";

const position = {
    latitude: 48.1880936,
    longitude: 14.5476313,
};
const positionVienna={
    latitude: 48.1946808,
    longitude: 16.3143718,
}

class MyMapBox extends Component {
    state = {
        viewport: {
            width: "100%",
            height: window.innerWidth < 990 ? 300 : 500,
            ...{latitude: (position.latitude+positionVienna.latitude)/2,longitude: (position.longitude+positionVienna.longitude)/2},
            zoom: 7,
            mapboxApiAccessToken: "pk.eyJ1IjoibWF0c2NoaW5lciIsImEiOiJjazF4c2F2N2cwZWJwM2VxdGZ1MjRjNmJqIn0.mpW7aGOL45wHRIkiIL2BSQ"
        },
    }

    render() {
        const markerSize = 33;
        return (
            <div>
                <ReactMapGL
                    {...this.state.viewport}
                    onViewportChange={(viewport) => this.setState({viewport})}
                    mapStyle='mapbox://styles/mapbox/streets-v11'
                >
                    <div style={{position: 'absolute', left: 10, top: 10}}>
                        <NavigationControl/>
                    </div>
                    <Marker {...position} offsetLeft={-markerSize / 2} offsetTop={-markerSize}>
                        <em style={{color: "#444", fontSize: markerSize + "px"}}>
                            <FaMapPin/>
                        </em>
                    </Marker>
                    <Marker {...positionVienna} offsetLeft={-markerSize / 2} offsetTop={-markerSize}>
                        <em style={{color: "#444", fontSize: markerSize + "px"}}>
                            <FaMapPin/>
                        </em>
                    </Marker>
                </ReactMapGL>
            </div>
        );
    }
}

class Contact extends Component {

    render() {
        return <div className="page-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contact">
                            <div className="map-wrapper">
                                <MyMapBox/>

                                {/*<div className="map-canvas"
                                     data-zoom="15"
                                     data-lat="48.1946808"
                                     data-lng="16.3143718"
                                     data-type="roadmap"
                                     data-hue="#ffc400"
                                     data-title="Beapp"
                                     data-icon-path="assets/images/marker-green.png"
                                     data-content="Linkomat GmbH<br/>Goldschlagstraße 110/30<br/>1150 Wien<br/><br/><a>max.matschiner ät linkom.at</a>">
                                </div>*/}

                            </div>
                            <div className="contact-info">
                                <div className="item">
                                    <i className="fa fa-location-arrow"/>
                                    <div className="txt">
                                        <span>Linkomat GmbH<br/>Steingasse 16<br/>4300 St. Valentin <br/><br/>Goldschlagstraße 110/30<br/>1150 Wien</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <i className="fa fa-phone"></i>
                                    <div className="txt">
                                        <span>+43 664 564 40 55</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <i className="fa fa-envelope"></i>
                                    <div className="txt">
                                        <span>max.matschiner ät linkom.at</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="contact-bottom">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <h5 className="margin-bottom-30">Kontakt</h5>
                                    <div className="contact-text">
                                        <p>Nehmen Sie mit uns Kontakt auf! </p>
                                        <p>Wir helfen Ihnen sowohl mit technischen Fragen zu unseren Produkten als auch mit Fragen zu den Lieferbedingungen und Leistungen.</p>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-md-6 col-sm-12">
                                    <div className="contact-form">
                                        <Form src="https://sfkieclviyrvzho.form.io/vereinfacht"/>
                                        {/*<div className="row">
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <input type="text" placeholder="Name" />
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12">
                                                <input type="text" placeholder="E-Mail" />
                                            </div>
                                            <div className="col-lg-12">
                                                <textarea placeholder="NACHRICHT"></textarea>
                                            </div>
                                            <div className="col-lg-12">
                                                <button className="btn-primary-line">SENDEN</button>
                                            </div>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    }
}

export default Contact;
import React, {Component} from "react";

class Impressum extends Component {

    render() {
        return <div className="page-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="contact" style={{textAlign: "right"}}>


                            <h1>Impressum</h1>

                            <br/>
                            Linkomat GmbH<br/>
                            Goldschlagstraße 110/30<br/>
                            AT-1150 Wien<br/><br/>

                            <i className="left">Firmenbuch-Nummer:</i> FN 452179w<br/>
                            <i className="left">Firmenbuch-Gericht:</i> Wien<br/>
                            <i className="left">Mitgliedschaft:</i> Wirtschaftskammer Wien<br/>
                            <i className="left">Gewerbe:</i> Dienstleistungen in der automatischen Datenverarbeitung und IT<br/>

                            <i className="left">UID-Nummer:</i> AT U70972557<br/>
                            <i className="left">DVR-Nummer:</i> 4016361<br/><br/>

                            <i className="left">Geschäftsführer:</i> Max J. Matschiner<br/>
                            <i className="left">Tel.-Nr.:</i> <a href="tel:06645644055">+43 (0) 664 5644055</a><br/>
                            <br/><br/>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Impressum
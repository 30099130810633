import React, {Component} from 'react';

import {FaCalendar, FaEnvelope, FaFileInvoice, FaLock, FaUsers, FaWind} from "react-icons/fa";
import {clubMap, domainMap} from "./custom";

class Funktionen extends Component {
    render() {

        return (
            <>
                <section className="section" id={"4arten"}>
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="center-heading">
                                    <h2 className="section-title">4 Arten auf die VEREINfacht die Vereinsverwaltung vereinfacht</h2>
                                </div>
                            </div>
                            <div className="offset-lg-3 col-lg-6">
                                <div className="center-text">

                                </div>
                            </div>
                        </div>


                        <div className="row">


                            <div className="col-lg-3 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
                                <a href="#kursregistration" className="features-small-item ">
                                    <div className="icon">
                                        <div className="hexagon-bg"></div>
                                        <i className="fa fa-users"></i>
                                    </div>
                                    <h5 className="features-title">Kursverwaltung</h5>
                                    <p>Dein Verein bietet unterschiedliche Kurse / Stunden an, für die man sich selbst anmelden können soll? </p>
                                </a>
                            </div>


                            <div className="col-lg-3 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.4s">
                                <a href="#kursregistration" className="features-small-item active">
                                    <div className="icon">
                                        <div className="hexagon-bg"></div>
                                        <i className="fa fa-th-large"></i>
                                    </div>
                                    <h5 className="features-title">All-In-One Verwaltung</h5>
                                    <p>VEREINfacht kombiniert eine Buchungs-, Bezahl- und Kurs-Plattform mit einer passiven Mitglieder-Verwaltung, Buchhaltung und Newsletter-Software.</p>
                                </a>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.6s">
                                <a href="#finanzen" className="features-small-item">
                                    <div className="icon">
                                        <div className="hexagon-bg"></div>
                                        <i className="fa fa-credit-card"></i>
                                    </div>
                                    <h5 className="features-title">Kursbezahlung</h5>
                                    <p>Einfach zurücklehnen – VEREINfacht unterstützt alle gängigen Bezahlmethoden (Kreditkarte, SEPA, SOFORT) und kontrolliert automatisiert, ob bezahlt wurde.</p>
                                </a>
                            </div>


                            <div className="col-lg-3 col-md-6 col-sm-6 col-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.8s">
                                <a href="#finanzen" className="features-small-item">
                                    <div className="icon">
                                        <div className="hexagon-bg"></div>
                                        <i className="fa fa-building"></i>
                                    </div>
                                    <h5 className="features-title">Verbindungen</h5>
                                    <p>VEREINfacht ermöglicht den problemlosen Austausch mit bestehenden Software-Lösungen, (Serienbrief)-Vorlagen oder dem Steuerberater.</p>
                                </a>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="section white padding-bottom-top-60 border-bottom bg-center-right" id="kursregistration">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mobile-bottom-fix" data-scroll-reveal="enter right move 30px over 0.6s after 0.3s">
                                {/*<img src="assets/images/photos/features/green-1.png" className="img-fluid float-left" alt="App" />*/}
                                <FaUsers style={{fontSize: "210px"}} className={"fa img-fluid float-left"}/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">Kurs-Buchung</h2>
                                </div>
                                <div className="left-text">
                                    <p></p>
                                    <ul>
                                        <li>Biete Kurse an, die unter bestimmten Anforderungen (Alter, Geschlecht, Gruppengröße, Anmeldefrist) gebucht werden können</li>
                                        <li>Trainer / Gruppenleiter erhalten automatisch Zugriff auf ihre Teilnehmer</li>
                                        <li>unkompliziertes Wartelisten-System bei Kapazitätsengpässen</li>
                                        <li>Kursbeiträge können auf unterschiedlichste Weise verrechnet werden</li>
                                        <li>Integration der zentral in VEREINfacht verwalteten Kurs-Angebote in bestehende Vereinswebsite</li>
                                        <li>Medienbereich zum Austausch von Videos, Musik, Anleitungen, ....</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section white padding-bottom-top-60 bg-center-left" id={"kalender"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">Kalender & Benachrichtigungen</h2>
                                </div>
                                <div className="left-text">

                                    <ul>
                                        <li>Kalender-Funktion für Mitglieder, Trainer und Vorstandsmitglieder und Verlinkung zu bestehenden Kalendern</li>
                                        <li>einfache Anwesenheitskontrolle durch Trainer, An- und Abmeldung durch Teilnehmer</li>
                                        <li>SMS und E-Mail-Mitteilungen zu Kurszeiten oder Kursänderungen von überall aus mit einem Knopfdruck versenden</li>
                                        <li>VEREINfacht ist auch als iOS / Android App verfügbar</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                                <FaCalendar style={{fontSize: "210px"}} className={"fa img-fluid float-right"}/>
                                {/*<img src="assets/images/photos/features/green-2.png" className="img-fluid float-left" alt="App" />*/}
                            </div>
                        </div>
                    </div>
                </section>


                <section className="section white padding-bottom-top-60 border-bottom bg-center-right" id={"finanzen"}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mobile-bottom-fix" data-scroll-reveal="enter right move 30px over 0.6s after 0.3s">
                                {/*<img src="assets/images/photos/features/green-1.png" className="img-fluid float-left" alt="App" />*/}

                                <FaFileInvoice style={{fontSize: "210px"}} className={"fa img-fluid float-left"}/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">Buchhaltung</h2>
                                </div>
                                <div className="left-text">
                                    <p></p>
                                    <ul>
                                        <li>alle Zahlungen automatisch über Bankdatenträger importierbar</li>
                                        <li>Generierung von übersichtlichen Finanzberichten</li>
                                        <li>Einnahmen-Ausgaben-Rechnung mit integrierter online Belegverwaltung</li>
                                        <li>Möglichkeit von BMD-kompatiblem Export</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="parallax" data-image="assets/images/photos/parallax.jpg">
                    <div className="parallax-content">
                        <div className="container paymentmethods">
                            <div className="row">
                                <div className="offset-lg-2 col-lg-8">
                                    <div className="info">
                                        <p>Damit können Mitglieder bei Ihnen bezahlen: </p>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12 api-btn-content">
                                    <a href="#" className="">
                                        <i className="fa fa-credit-card"></i>
                                        <span>Kreditkarten</span>
                                    </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 api-btn-content">
                                    <a href="#" className="">
                                        <i className="fa fa-money"></i>
                                        <span>SEPA-Lastschrift</span>
                                    </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 api-btn-content">
                                    <a href="#" className="">
                                        <i className="fa fa-building"></i>
                                        <span>Banküberweisungen <br/>mit automatischem Import</span>
                                    </a>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 api-btn-content">
                                    <a href="#" className="">
                                        <FaWind className={"fa"}/>
                                        <span>SOFORT-ÜBERWEISUNGEN</span>
                                    </a>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>

                <section className="section white padding-bottom-top-60 border-bottom bg-center-right" id={"newsletter"}>
                    <div className="container ">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mobile-bottom-fix" data-scroll-reveal="enter right move 30px over 0.6s after 0.3s">
                                {/*<img src="assets/images/photos/features/green-1.png" className="img-fluid float-left" alt="App" />*/}

                                <FaEnvelope style={{fontSize: "210px"}} className={"fa img-fluid float-left"}/>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">Newsletter & Rundschreiben</h2>
                                </div>
                                <div className="left-text">
                                    <p></p>
                                    <ul>
                                        <li>Newsletter-Software mit Drag & Drop Bearbeitung und Empfangs-Überwachung</li>
                                        <li>Serienbrief-Funktion mit Microsoft Word Vorlage</li>
                                        <li>In jeder Version unlimitierte Anzahl an E-Mails und SMS (inkludiert)</li>
                                        <li>ungebrandetes Auftreten nach außen bzw. Platzierung des Vereins-Logos / der Vereinsfarben</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section white padding-bottom-top-60 border-bottom bg-center-right">
                    <div className="container ">
                        <div className="row">

                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center">
                                <div className="left-heading">
                                    <h2 className="section-title">Sicher & Konform</h2>
                                </div>
                                <div className="left-text">
                                    <p></p>
                                    <ul>
                                        <li>SSL Verschlüsselung zur sicheren Zahlungsabwicklung und Interaktion</li>
                                        <li>DSGVO konform</li>
                                        <li>Datenspeicherung und Hosting gemäß ISO 27001</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 align-self-center mobile-bottom-fix" data-scroll-reveal="enter right move 30px over 0.6s after 0.3s">
                                {/*<img src="assets/images/photos/features/green-1.png" className="img-fluid float-left" alt="App" />*/}

                                <FaLock style={{fontSize: "210px", color: "#bbdb52"}} className={"fa img-fluid float-right"}/>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="section" id="mitgliederverwaltung">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="center-heading">
                                    <h2 className="section-title">Benutzermeinungen</h2>
                                </div>
                            </div>
                            {/*<div className="offset-lg-3 col-lg-6">
                                <div className="center-text">
                                    <p>Donec vulputate urna sed rutrum venenatis. Cras consequat magna quis arcu elementum, quis congue risus volutpat.</p>
                                </div>
                            </div>*/}
                        </div>


                        <div className="row testimonials-wrapper">

                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="testimonials-item">
                                    <div className="hexagon-user">
                                        <div className="hexagon-user-img">
                                            <div className="hexagon-in1">
                                                <div className="hexagon-in2" data-profile-img="assets/images/photos/profile/theresa.jpg"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonials-content">
                                        <h3 className="user-name">Theresa Gansterer</h3>
                                        <span>Sportliche Leitung, <br/>Turnverein St. Valentin</span>
                                        <p>VEREINfacht erleichert meine Arbeit, da ich neue Kurse leicht und interaktiv hinzufügen kann. Außerdem kann ich jederzeit übersichtliche Statistiken zu
                                            unseren Mitgliedern abrufen.</p>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="testimonials-item">
                                    <div className="hexagon-user">
                                        <div className="hexagon-user-img">
                                            <div className="hexagon-in1">
                                                <div className="hexagon-in2" data-profile-img="assets/images/photos/profile/nicole.jpg"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonials-content">
                                        <h3 className="user-name">Nicole Kapeller</h3>
                                        <span>Buchhaltung,<br/> Turnverein St. Valentin</span>
                                        <p>VEREINfacht erleichtert meine Arbeit, da die Zahlungen automatisch erfasst werden, die Buchhaltung und Mitgliederverwaltung an einem Ort geschehen und die Endberichte direkt an den
                                            Steuerberater übermittelt werden können.</p>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="testimonials-item">
                                    <div className="hexagon-user">
                                        <div className="hexagon-user-img">
                                            <div className="hexagon-in1">
                                                <div className="hexagon-in2" data-profile-img="assets/images/photos/profile/anna.jpg"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonials-content">
                                        <h3 className="user-name">Anna Grasegger</h3>
                                        <span>Marketing,<br/>Turnverein St. Valentin</span>
                                        <p>VEREINfacht konnte ich direkt in unsere Vereins-Homepage integrieren um den nahtlosen Übergang von der Kundengewinnung in unser Verwaltungssystem zu
                                            gewährleisten.</p>
                                    </div>
                                </div>
                            </div>


                            {/*
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="testimonials-item">
                                    <div className="hexagon-user">
                                        <div className="hexagon-user-img">
                                            <div className="hexagon-in1">
                                                <div className="hexagon-in2" data-profile-img="assets/images/photos/profile/4.jpg"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonials-content">
                                        <h3 className="user-name">Gunther Bearder</h3>
                                        <span>Jade Brook Resort</span>
                                        <p>Proin fringilla molestie vulputate. Suspendisse vitae elit eget ipsum sagittis aliquet. Proin sagittis luctus mauris non sollicitudin. Donec dapibus.</p>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="testimonials-item">
                                    <div className="hexagon-user">
                                        <div className="hexagon-user-img">
                                            <div className="hexagon-in1">
                                                <div className="hexagon-in2" data-profile-img="assets/images/photos/profile/5.jpg"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonials-content">
                                        <h3 className="user-name">Girth Wiedend</h3>
                                        <span>Queen's Hotel & Spa</span>
                                        <p>Aenean fringilla tellus nisl, placerat venenatis lectus feugiat id. Nam nisi sem, tristique vitae lectus sodales, ullamcorper gravida felis. Vestibulum.</p>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="testimonials-item">
                                    <div className="hexagon-user">
                                        <div className="hexagon-user-img">
                                            <div className="hexagon-in1">
                                                <div className="hexagon-in2" data-profile-img="assets/images/photos/profile/6.jpg"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="testimonials-content">
                                        <h3 className="user-name">Hanson Deck</h3>
                                        <span>Sublime Quest Resort</span>
                                        <p>Integer elementum cursus dui. Praesent dolor risus, elementum vitae arcu eu, cursus imperdiet justo. Pellentesque vel pellentesque orci. Morbi tempor.</p>
                                    </div>
                                </div>
                            </div>*/}

                        </div>
                    </div>
                </section>
                <section className="section" id="mitgliederverwaltung" style={{paddingTop: "30px"}}>
                    <div className="container referenceContainer">

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="center-heading">
                                    <h2 className="section-title">Welche Vereine wurden bereits VEREINfacht?</h2>
                                </div>
                            </div>
                            {/*<div className="offset-lg-3 col-lg-6">
                                <div className="center-text">
                                    <p>Donec vulputate urna sed rutrum venenatis. Cras consequat magna quis arcu elementum, quis congue risus volutpat.</p>
                                </div>
                            </div>*/}
                        </div>
                        {
                            Object.values(domainMap)
                                .filter(club => ![1, 2, 7, 14,26].includes(club.verein_ID))
                                .map(club => <div className={"referenceClub"}><a target={"_blank"} href={"http://" + club.website}>{{8: <img src={"/icons/itv_logo.png"}/>}[club.verein_ID] || <div dangerouslySetInnerHTML={{__html: club.logo}}/>}</a></div>)
                        }
                    </div>
                </section>

                <section className="parallax-counter" data-image="assets/images/photos/parallax-counter.jpg">
                    <div className="parallax-content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="count-item">
                                        <strong> {
                                            Object.values(domainMap)
                                                .filter(club => ![-1, 1].includes(club.verein_ID)).length
                                        }</strong>
                                        <span>Glückliche <br/>Kunden</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="count-item">
                                        <strong>11399</strong>
                                        <span>Mitglieder verwaltet</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="count-item">
                                        <strong>160000</strong>
                                        <span>Buchungen</span>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="count-item">
                                        <strong>393688</strong>
                                        <span>Email / SMS <br/>Interkationen</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*
                <section className="section white bg-center-left" id="finanzen">
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="center-heading">
                                    <h2 className="section-title">Blog</h2>
                                </div>
                            </div>
                            <div className="offset-lg-3 col-lg-6">
                                <div className="center-text">
                                    <p>Donec vulputate urna sed rutrum venenatis. Cras consequat magna quis arcu elementum, quis congue risus volutpat.</p>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="blog-post-thumb">
                                    <div className="img">
                                        <img src="assets/images/photos/blog/1.jpg" alt="" />
                                    </div>
                                    <h3>
                                        <a href="green-blog-single.html">How to select the best channel manager for your hotel?</a>
                                    </h3>
                                    <div className="text">
                                        Mauris tellus sem, ultrices varius nisl at, convallis iaculis mauris. Sed eget sem vitae purus tempus dignissim.
                                    </div>
                                    <a href="green-blog-single.html" className="btn-primary-line">Read More</a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="blog-post-thumb">
                                    <div className="img">
                                        <img src="assets/images/photos/blog/2.jpg" alt="" />
                                    </div>
                                    <h3>
                                        <a href="green-blog-single.html">Find out where you earn most money – Hexagon statistics</a>
                                    </h3>
                                    <div className="text">
                                        Cras imperdiet faucibus sem, a dignissim urna feugiat sed. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                                    </div>
                                    <a href="green-blog-single.html" className="btn-primary-line">Read More</a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="blog-post-thumb">
                                    <div className="img">
                                        <img src="assets/images/photos/blog/3.jpg" alt="" />
                                    </div>
                                    <h3>
                                        <a href="green-blog-single.html">Earn more profits from Hexagon than high seasons!</a>
                                    </h3>
                                    <div className="text">
                                        Quisque euismod nec lacus sit amet maximus. Ut convallis sagittis lorem auctor malesuada. Morbi auctor tortor eu risus condimentum.
                                    </div>
                                    <a href="green-blog-single.html" className="btn-primary-line">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>*/}
            </>
        )
    }
}

export default Funktionen

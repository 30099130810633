import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import "babel-polyfill";
import {polyfill} from 'es6-promise';
import Preise from "./Preise"
import Startseite from "./Startseite"
import Header from "./Header"
import "./max.sass"
import Contact from "./Contact"
import Funktionen from "./Funktionen"
import Impressum from "./Impressum";
polyfill();
class App extends Component {

    render() {
        return (
            <>
                <Router onUpdate={() => window.scrollTo(0, 0)}>
                    <>
                        <Route path="/" component={Header}/>
                        <section className="page">
                            <Route path="/" exact component={Startseite}/>


                            <Route path="/funktionen" component={Funktionen}/>
                            <Route path="/" exact component={Funktionen}/>

                            <Route path="/preise" component={Preise}/>
                            <Route path="/" exact component={Preise}/>

                            <Route path="/kontakt" component={Contact}/>
                            <Route path="/" exact component={Contact}/>


                            <Route path="/impressum" component={Impressum}/>

                        </section>
                    </>
                </Router>


            </>
        );
    }
}

export default App;

import React, {Component} from 'react';
import logo from "./Logo-vereinfacht-05.png"

class Header extends Component {
    componentDidMount = ()=>{
       /* if($('.menu-trigger').length){
            $(".menu-trigger").on('click', function() {
                $(this).toggleClass('active');
                $('.header-area .nav').slideToggle(200);
            });
        }*/
    }
    render() {

        return <header className={"header-area "+ (this.props.location.pathname!=="/"?"header-white":"")} >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <nav className="main-nav">
                            <a href="/" className="logo">
                                <img src={logo} alt="Hexagon"/>
                            </a>


                            <ul className="nav">
                                <li><a href="/">Startseite</a></li>

                                <li className="submenu">
                                    <a href={"/#kursregistration"} style={{cursor: "pointer"}}>Funktionen</a>
                                    <ul>
                                        <li><a href="/#kursregistration">Kursregistration</a></li>
                                        <li><a href="/#kalender">Mitgliederverwaltung</a></li>
                                        <li><a href="/#finanzen">Finanzen</a></li>
                                        <li><a href="/#newsletter">Newsletter</a></li>
                                    </ul>
                                </li>
                                <li><a href="/#preise">Preise</a></li>
                                <li><a href="/kontakt" className="btn-nav-line"><span>Mehr Erfahren</span></a></li>
                            </ul>
                            <a className='menu-trigger'>
                                <span>Menu</span>
                            </a>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    }
}
export default Header
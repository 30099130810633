import React, {Component} from 'react';

class Startseite extends Component {
    render(){
        return <div className="welcome-area" id="welcome">
            <div className="right-bg">
                <img src="assets/images/photos/header3.jpg" className="img-fluid float-right" alt=""/>
            </div>
            <div className="header-hexagon-bg">
                <img src="assets/images/header-bg.svg" className="img-fluid" alt=""/>
            </div>

            <div className="header-text">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <h1>Vereinsverwaltung <br/>neu definiert</h1>
                            <p>Jedes Mitglied bekommt selbst Zugang und kann sich somit selbst verwalten. Im Hintergrund werden alle Funktionen, die sich ein Verein erwarten würde, in einer ebenso einfach benutzbaren Cloud-Lösung verknüpft – also der einfache ALLESKÖNNER.</p>
                            <div>
                                <div >

                                    <a className={"btn-primary-line"} href={"/#4arten"}>entdecken</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    }
}
export default Startseite;

import React, { Component } from 'react';


class Preise extends Component {
    render() {
        return (
            <section className="section white bg-center-left" id="preise">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="center-heading">
                                <h2 className="section-title">Preisübersicht</h2>
                            </div>
                        </div>
                        <div className="offset-lg-3 col-lg-6">
                            <div className="center-text">
                                <p>VEREINfacht ist sowohl für kleinere Vereine, als auch für Institutionen oder Verbände von mehreren Vereinen optimal geeignet. Hier finden Sie unsere Preismodelle:</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.2s">
                            <div className="pricing-item">
                                <div className="pricing-header">
                                    <h3 className="pricing-title">STARTER</h3>
                                    <div className="number-hexagon">
                                        <span>1</span>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <div className="price-wrapper">
                                        <span className="currency">€</span>
                                        <span className="price">3,00<br/></span>
                                        <span className="period">pro Jahr und Mitglied</span>

                                    </div>
                                    <ul className="list">
                                        <li>bis zu 300 Mitglieder</li>
                                        <MainFeatures/>

                                        {/*<li>Data Security and Backups</li>
                                        <li>Unlimited Staff Accounts</li>
                                        <li>Web Booking Widget</li>
                                        <li>Monthly Reports and Analytics</li>*/}
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                    <a href="/kontakt">BESTELLEN</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.4s">
                            <div className="pricing-item active">
                                <div className="pricing-header">
                                    <h3 className="pricing-title">ALLESKÖNNER</h3>
                                    <div className="number-hexagon">
                                        <span>2</span>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <div className="price-wrapper">
                                        <span className="currency">€</span>
                                        <span className="price">3,00<br/></span>
                                        <span className="period">pro Jahr und Mitglied</span>

                                    </div>
                                    <ul className="list">
                                        <li>bis zu 2000 Mitglieder</li>
                                        <MainFeatures/>
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                    <a href="/kontakt">BESTELLEN</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-4 col-sm-12" data-scroll-reveal="enter bottom move 50px over 0.6s after 0.6s">
                            <div className="pricing-item">
                                <div className="pricing-header">
                                    <h3 className="pricing-title">OHNE LIMITS</h3>
                                    <div className="number-hexagon">
                                        <span>3</span>
                                    </div>
                                </div>
                                <div className="pricing-body">
                                    <div className="price-wrapper">

                                        <span className={"period"}>Preis auf Anfrage</span><br/>
                                    </div>
                                    <ul className="list">
                                        <li>unbeschränkte Mitgliederanzahl</li>
                                        <MainFeatures/>
                                    </ul>
                                </div>
                                <div className="pricing-footer">
                                    <a href="/kontakt">BESTELLEN</a>
                                </div>
                            </div>
                        </div>
                    </div>
                Alle Preise sind inkl. Umsatzsteuer.
                </div>
            </section>
        )
    }
}
function MainFeatures(){
    return <>
        <li className="active">erprobte regeldefinierte Kursregistration</li>
        <li className="active">Personen können sich selbst und ihre Freunde verwalten</li>
        <li className="active">Kursbezahlmethoden <br/>(Kreditkarte, SEPA, SOFORT)</li>
        <li className="active">Übersichtliche Statistiken</li>
        <li className="active">Umfangreiches Buchhaltungsmodul</li>
        <li className="active">Newslettersoftware inkludiert</li>
        </>
}
export default Preise
